.tagline {
  text-align: right;
}

.menuMain .topLevel {
  border-top: 1px solid #28346226;
}

@media (min-width: map-get($breakpoints, sm) + 1px) {
  .homeBox3 [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    right: 40px;
  }
}

.homeBox3 h2 a {
  color: $brand-primary;
}

//footer sorry couldn't find this in the variables
.pageFooter {
  padding: 4rem 0;
}

.footerBox1 {
  padding-right: 40px;
}

.mainCallToAction a.cta-button.edspace {
  background-color: #f76262;
}

.donateBtn {
  background-color: $donate-colour;
}

.donateBtn:hover {
  background-color: darken($donate-colour, 10%);
}

// Homepage quick giving - Layout
.homeFeature .homepage-quickgiving--container {
  flex-direction: column;

  .homepage-quickgiving--copy {
    margin: 0 0 20px 0;
  }
}

// Post - Content Block
.post .contentBlock {
  display: block;
}

// Better mobile header IM-737
@media (max-width: 576px) {
  .pageHeader .headerContent {
    flex-wrap: wrap;
    padding: 0;
  }
  .mainLogo {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    background-position: center;
  }
  .mainCallToActionButtons {
    width: 100%;
    display: flex;
    a {
      flex: 1;
      margin: 0 2px !important;
    }
  }
}