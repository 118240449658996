// $platform-name: 'capabilitybuildingincorporated';

//
// Config values
//

$platform-name: "capabilitybuildingincorporated";
$assets-path: "../assets/";
$site-bleed: 20px;

//
// Colours
//
$brand-primary: #293462;
$brand-secondary: #216583;
$grey: #6c6d70;
$donate-colour: #cc363c;
$yellow: #fff1c1;
// Create greyscale

// Utility colours

//
// Layout
//
$border-radius: 6px;

// Breakpoints

// Spacers
$spacer: 1rem !default;
$spacer-y: $spacer !default;
$spacer-x: $spacer !default;

//
// Misc
//
$body-colour: $grey;

//
// Transitions
//

//
// Logo
//
$logo: "logo.png";
$logo-width: 336px;
$logo-height: 63px;
$logo-mobile-width: $logo-width * 0.9;
$logo-mobile-height: $logo-height * 0.9;
//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Merriweather", serif;
$headings-colour: #58595b;
$font-weight-bold: 900;
// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 2em;
$btn-padding-y: 1em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-text-colour: #fff;

// Donate button
$donate-btn-background-colour: #cc363c;
$donate-btn-colour: #FFF;
//
// Social icons
//

$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: #989898;
$social-icons-colour: #fff;

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-gap: 10px;
// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;

//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
$card-hover-summary-colour: white;
$card-text-align: center;
$card-box-shadow: 0 2px 0 #28346226;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

//
// Menu admin
//

// $menu-admin-enabled: false;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;
$basket-link-only-on-shop-pages: true;

//
// Header
//

// Header content
$header-content-margin-bottom: 0.5rem;

// Tagline
$tagline-enabled: true;
$tagline-font-family: $font-open-sans;
$tagline-above-cta-breakpoint: 992px;
$tagline-breakpoint: 850px;


// Main call to action
$cta-btn-gap: 10px;
$cta-btn-colour: white;

// $cta-padding-right-at-nav-breakpoint: 6.5em !default; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint

// Search
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-button-background-colour: #989898;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-input-margin-right: 5px;
$header-search-margin-right: 10px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search - input

// Search - button

// Social icons

// Colours set above

//
// Sticky header
//

//
// Navigation
//

$nav-type: normal;
$nav-background-colour: transparent;
$nav-normal-align-items: left;
$nav-top-level-item-colour: #000;
$nav-font-family: $font-open-sans;
// Top level items
$nav-top-level-item-colour: #58595b;
$nav-top-level-item-font-weight: 600;
$nav-top-level-item-padding: 15px 30px;
// Submenus
$nav-submenu-item-colour: #58595b;
// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;

//
// Carousel
//
$carousel-max-width: 100%;
$carousel-details-background-colour: rgba(#fff, 0.8);
$carousel-details-max-width: 500px;
$carousel-details-position-y: center;
$carousel-details-padding: 3rem;
$carousel-details-margin-x: 40px;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: $carousel-details-background-colour;
$carousel-controls-icon-colour: $brand-primary;

// Carousel controls (left-right buttons)
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-button-border-radius: 0;

// Carousel controls (left-right buttons)
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;

// Carousel buttons (dots or tabs)
$carousel-dots-position-x: center;

// Carousel buttons dots
$carousel-dots-active-colour: white;

// Carousel buttons tabs

// Carousel after breakpoint

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 1;
$home-intro-max-width: 980px;
$home-intro-margin-top: 3rem;
//
// Home features
//
$home-features-padding-bottom: $spacer * 2;
$home-features-padding-top: $spacer * 2;
//
// Impact stats
//
$impact-stats-background-colour: transparent;
$impact-stats-padding-y: $spacer * 4;
$impact-stats-colour: $brand-secondary;

// Heading

// Individual stat
$impact-stats-heading-colour: $headings-colour;
$impact-stats-figure-colour: $brand-secondary;
// Figure
$impact-stats-figure-font-family: $font-open-sans;
// Summary
$impact-stats-summary-colour: $grey;
//
// Home feeds
//
$home-feeds-background-colour: #FFF;
$home-feeds-padding-top: $spacer * 3;
// Feeds title
$feeds-title-colour: #333;
$feeds-title-text-align: left;
// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;

// Twitter feed

//
// Footer
//
$footer-boxes-count: 3;
$footer-prefab: 5;
$footer-background-colour: $brand-primary;
$footer-link-colour: white;
$footer-padding-y: $spacer * 6;
// Footer admin links

// Newsletter
$newsletter-heading-text-align: left;

$newsletter-background-colour: $brand-primary;

$newsletter-button-colour: $brand-primary;
$newsletter-button-background-colour: white;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//
$post-content-max-width: 910px;
//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
$sidebar-enabled: false;

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//
// Quick giving panel
//

$quick-giving-type: simple;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $brand-primary;
$quick-giving-donation-amount-border-width: 1px;
$quick-giving-donation-amount-border-colour: $brand-primary;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;
$quick-giving-donation-amount-selected-figure-colour: $white;


// Home quick giving panel
//

$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: 100%;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 3;
$home-quick-giving-background-colour: #EFEFEF;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $brand-primary;
$home-quick-giving-donation-amount-border-width: 1px;
$home-quick-giving-donation-amount-border-colour: $brand-primary;
$home-quick-giving-donation-amount-selected-background-colour: $brand-primary;
$home-quick-giving-donation-amount-selected-figure-colour: $white;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-donate-btn-border-colour: 1px;


// Home quick giving panel
//

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//
$subsite-enabled: true;
$subsite-logo: 'title';

// Subsite nav

//
// Cookie consent
//
